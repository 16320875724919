<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="dal-comp-customer">
  <el-table id="a49b6d9c" :data="tbd_a49b6d9c" stripe="" border="">
    <el-table-column prop="b083756a" label="客户名称">
    </el-table-column>
    <el-table-column prop="aaf1e3a8" label="电话号码">
    </el-table-column>
    <el-table-column prop="cefeab4c" label="电子邮箱">
    </el-table-column>
    <el-table-column prop="a147e563" label="登记时间">
    </el-table-column>
    <el-table-column prop="a137b1e3" label="销售专员">
    </el-table-column>
    <el-table-column prop="ad08ceca" label="产品经理">
    </el-table-column>
    <el-table-column prop="adb25fe9" label="项目经理">
    </el-table-column>
    <el-table-column prop="a2ac3f44" label="备注">
    </el-table-column>
  </el-table>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    tbd_a49b6d9c:[]
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a49b6d9c{
  width:1322px;
}
#a49b6d9c{
  display:inline-block;
  vertical-align: middle;
}
/*在此注释下方添加自定义样式*/
</style>
