<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Customerlist" style="width:100vw">
  <div id="aac559ce">
    客户列表
  </div>
  <el-button id="a70423eb" @click="clicka70423eb()">
    +新增客户
  </el-button>
  <div id="c5a8c218">
    <dal-comp-customer id="cmpc5a8c218">
    </dal-comp-customer>
  </div>
  <el-pagination id="a49718a6">
  </el-pagination>
  <div id="dlgfd9862a64" v-show="vis_d9862a64" @click.self="vis_d9862a64=false">
    <div id="d9862a64">
      <div id="abe92aaa">
        新增客户
      </div>
      <div id="abdee9fa">
        <div id="a1f7b93a">
          客户列表
        </div>
        <el-input id="d51eff56" v-model="inp_d51eff56">
        </el-input>
      </div>
      <el-table id="abcccffc" :data="tbd_abcccffc" stripe="" border="">
        <el-table-column prop="a60fbfb8" label="全选">
        </el-table-column>
        <el-table-column prop="a214aeb0" label="客户姓名">
        </el-table-column>
        <el-table-column prop="ddaa613a" label="电子邮箱">
        </el-table-column>
        <el-table-column prop="a2746ce5" label="登记时间">
        </el-table-column>
        <el-table-column prop="af1b6633" label="手机号">
        </el-table-column>
      </el-table>
      <div id="fd889194">
        <el-button id="a5f0bf10" @click="clicka5f0bf10()">
          确认
        </el-button>
        <el-button id="a03c6d43" @click="clicka03c6d43()">
          取消
        </el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_comp_customer from '../components/dal-comp-customer'
export default{
  components:{
    'dal-comp-customer':dal_comp_customer
  },
  data(){
    return{
    vis_d9862a64:false,
    inp_d51eff56:'',
    tbd_abcccffc:[],
      //在此注释下方添加自定义变量
    tbd_aeee4320:[]
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    async clicka70423eb(){
      this.vis_d9862a64 = true
    },
    async clicka5f0bf10(){
    },
    async clicka03c6d43(){
    },
    //在此注释下方添加自定义函数或事件
    async clicka503b5a5(){
    },
    async clicka3d8070c(){
    }
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#aac559ce{
  padding-top:100px;
}
#aac559ce{
  text-align:center;
  color:#1c1c1c;
  font-size:30px;
  font-family:PingFang SC;
}
#a70423eb{
  width:120px;
  margin-top:30px;
  margin-left:1365px;
}
#a70423eb{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a70423eb{
  color:#fff;
  font-size:13px;
  font-family:PingFang SC;
  border:transparent;
}
#c5a8c218{
  width:1322px;
  margin-top:30px;
  padding-left:163px;
}
#a49718a6{
  width:302px;
  margin-top:52px;
  padding-left:673px;
  padding-bottom:1720px;
}
#dlgfd9862a64{
  position:fixed;
  z-index: 100;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background:rgba(0,0,0,0.5);
}
#d9862a64{
  position:absolute;
  top:864px;
  left:610px;
  width:700px;
  height:561px;
  border-radius:10px 10px 10px 10px;
  background:#fff;
}
#abe92aaa{
  padding-top:30px;
}
#abe92aaa{
  text-align:left;
  color:#333;
  font-size:24px;
  font-family:PingFang SC;
}
#abdee9fa{
  width:618px;
  position:relative;
  margin-top:20px;
  padding-left:40px;
  display:inline-block;
  vertical-align: middle;
}
#a1f7b93a{
  padding-top:2px;
  padding-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#a1f7b93a{
  text-align:center;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#abdee9fa .el-input{
  width:280px;
  margin-left:286px;
  display:inline-block;
  vertical-align: middle;
}
#abdee9fa .el-input .border{
  border:1px solid #979797;
  border-radius:30px;
}
#abcccffc{
  width:616px;
  margin-top:20px;
  margin-left:42px;
}
#abcccffc{
  display:inline-block;
  vertical-align: middle;
}
#fd889194{
  width:404px;
  position:relative;
  margin-top:40px;
  padding-left:148px;
  padding-bottom:30px;
}
#a5f0bf10{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#a5f0bf10{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#a5f0bf10{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#a03c6d43{
  width:142px;
  margin-left:120px;
  display:inline-block;
  vertical-align: middle;
}
#a03c6d43{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#a03c6d43{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
